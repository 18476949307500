<template>
  <main class="container">
    <CAlert v-if="$store.state.project.Trial && $dayjs($store.state.project.TrialExpired * 1000).diff($dayjs().format('YYYY-MM-DD'), 'day') < 30 && $dayjs($store.state.project.TrialExpired * 1000).diff($dayjs().format('YYYY-MM-DD'), 'day') > 0" color="warning" class="mb-3">
      <i class="fas fa-exclamation-triangle mr-2" />您的方案將於 {{ $dayjs($store.state.project.TrialExpired * 1000).diff($dayjs().format('YYYY-MM-DD'), 'day') }} 天後過期，請聯繫客服人員協助開通事宜。
    </CAlert>
    <CAlert v-else-if="$store.state.project.Trial && $dayjs($store.state.project.TrialExpired * 1000).diff($dayjs().format('YYYY-MM-DD'), 'day') <= 0" color="danger" class="mb-3">
      <i class="fas fa-exclamation-triangle mr-2" />您的方案已過期，部分功能已受限制，請聯繫客服人員協助開通事宜。
    </CAlert>
    <CRow>
      <CCol v-if="(user.permission.Navigation.Report || []).includes('Order') || (user.permission.Navigation.Report || []).includes('Profit')" sm="6" xl="3">
        <AdvanceWidgetDropdown color="secondary" textColor="black" :header="(TodayOrder.TotalItems || '0')" text="今日訂單">
          <div>
            <span class="small" :class="(TodayOrder.TotalItems - YesterdayOrder.TotalItems) >= 0 ? 'text-success' : 'text-danger'"><i class="mr-1 fas" :class="(TodayOrder.TotalItems - YesterdayOrder.TotalItems) >= 0 ? 'fa-caret-up' : 'fa-caret-down'" />{{ (TodayOrder.TotalItems - YesterdayOrder.TotalItems) || '0' }}</span>
          </div>
          <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.5" class="py-5">
            <CSpinner color="warning"/>
          </CElementCover>
          <template #footer>
            <CChartBarSimple
              class="mt-3"
              style="height:75px"
              :data-points="WeeklyOrderItems"
              label="訂單數"
              background-color="rgba(255,255,255,.5)"
              :labels="ChartDateLabels"
            />
          </template>
        </AdvanceWidgetDropdown>
      </CCol>
      <CCol v-if="(user.permission.Navigation.Report || []).includes('Order')" sm="6" xl="3">
        <AdvanceWidgetDropdown color="secondary" textColor="black" :header="`${$store.state.currency}$${$amount.format(TodayOrder.TotalAmount || '0')}`" text="今日營業額">
          <div>
            <span class="small" :class="(TodayOrder.TotalAmount - YesterdayOrder.TotalAmount) >= 0 ? 'text-success' : 'text-danger'"><i class="mr-1 fas" :class="(TodayOrder.TotalAmount - YesterdayOrder.TotalAmount) >= 0 ? 'fa-caret-up' : 'fa-caret-down'" />{{ $store.state.currency + '$' }}{{ $amount.format(TodayOrder.TotalAmount - YesterdayOrder.TotalAmount || 0) }}</span>
          </div>
          <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.5" class="py-5">
            <CSpinner color="warning"/>
          </CElementCover>
          <template #footer>
            <CChartBarSimple
              class="mt-3"
              style="height:75px"
              :data-points="WeeklyOrderAmount"
              label="營業額"
              background-color="rgba(255,255,255,.5)"
              :labels="ChartDateLabels"
            />
          </template>
        </AdvanceWidgetDropdown>
      </CCol>
      <CCol v-if="(user.permission.Navigation.Report || []).includes('Order')" sm="6" xl="3">
        <AdvanceWidgetDropdown color="secondary" textColor="black" :header="`${$store.state.currency}$${$amount.format(TodayOrder.PaymentAmount || '0')}`" text="今日實收金額">
          <div>
            <span class="small" :class="(TodayOrder.PaymentAmount - YesterdayOrder.PaymentAmount) >= 0 ? 'text-success' : 'text-danger'"><i class="mr-1 fas" :class="(TodayOrder.PaymentAmount - YesterdayOrder.PaymentAmount) >= 0 ? 'fa-caret-up' : 'fa-caret-down'" />{{ $store.state.currency + '$' }}{{ $amount.format(TodayOrder.PaymentAmount - YesterdayOrder.PaymentAmount || '0') }}</span>
          </div>
          <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.5" class="py-5">
            <CSpinner color="warning"/>
          </CElementCover>
          <template #footer>
            <CChartBarSimple
              class="mt-3"
              style="height:75px"
              :data-points="WeeklyPaymentAmount"
              label="營業額"
              background-color="rgba(255,255,255,.5)"
              :labels="ChartDateLabels"
            />
          </template>
        </AdvanceWidgetDropdown>
      </CCol>
      <CCol v-if="(user.permission.Navigation.Organization || []).includes('Member')" sm="6" xl="3">
        <AdvanceWidgetDropdown color="secondary" textColor="black" :header="(TodayRegister || '0')" text="今日會員註冊">
          <div>
            <span class="small" :class="(TodayRegister - YesterdayRegister) >= 0 ? 'text-success' : 'text-danger'"><i class="mr-1 fas" :class="(TodayRegister - YesterdayRegister) >= 0 ? 'fa-caret-up' : 'fa-caret-down'" />{{ TodayRegister - YesterdayRegister }}</span>
          </div>
          <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.5" class="py-5">
            <CSpinner color="warning"/>
          </CElementCover>
          <template #footer>
            <CChartBarSimple
              class="mt-3 mx-3"
              style="height:75px"
              :data-points="WeeklyRegister"
              background-color="rgba(255,255,255,.5)"
              label="會員註冊數"
              :labels="ChartDateLabels"
            />
          </template>
        </AdvanceWidgetDropdown>
      </CCol>
    </CRow>
    <CRow>
      <CCol v-if="(user.permission.Features && user.permission.Features.Order || []).includes('Message')" md="12">
        <CCard class="shadow-sm">
          <CCardHeader>
            <h5 class="mb-0">{{ $t('Order.Message.UnprocessedMessage') }}</h5>
          </CCardHeader>
          <CCardBody class="p-0">
            <CDataTable
              id="OrderList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              addTableClasses="text-nowrap mb-0"
              responsive
            >
              <template #no-items-view>
                <CAlert color="success" class="mb-0">
                  <i class="fas fa-check-circle mr-1" />{{ $t('Order.Message.NoUnprocessedMessage') }}
                </CAlert>
              </template>
              <template #OrderNum="{item}">
                <td>
                  <a href="#" class="btn-link text-info" @click="HandleExternalLink(item.OrderNum)">
                    {{ item.OrderNum }}
                  </a>
                </td>
              </template>
              <template #OrderClient="{item}">
                <td>
                  <div class="text-truncate" style="max-width: 200px">
                    {{ item.Content }}
                  </div>
                </td>
              </template>
              <template #EventTime="{item}">
                <td>
                  {{ $dayjs(item.EventTime).format('YYYY-MM-DD HH:mm:ss') }}
                </td>
              </template>
              <template #PaymentStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.PaymentStatus]">
                    {{ $t('Order.PaymentStatusList.' + item.PaymentStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #LogisticsStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.LogisticsStatus]">
                    {{ $t('Order.LogisticsStatusList.' + item.LogisticsStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #OrderStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.OrderStatus]">
                    {{ $t('Order.OrderStatusList.' + item.OrderStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #BonusActionType="{item}">
                <td>
                  <CButton color="info" size="sm" class="mr-2" @click="HandleExternalLink(item.OrderNum)">
                    <CIcon name="cil-external-link" class="c-icon-custom-size mr-1" /> {{ $t('Order.ActivityLogEvent.CheckOrder') }}
                  </CButton>
                  <CButton color="success" size="sm" @click="UpdateMessageStatus(item.OrderNum)">
                    <CIcon name="cil-check-circle" class="c-icon-custom-size mr-1" /> {{ $t('Order.Message.DoProcessed')}}
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter class="p-0">
            <CButton block class="text-left py-2 px-3" @click="$router.push('/order/process/message')">
              <i class="fas fa-arrow-right mr-2" /> {{ $t('Dashboard.GoOrderMessageManage') }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol v-if="(user.permission.Navigation.Order || []).includes('ManageProcess')" md="12">
        <CCard class="shadow-sm">
          <CCardHeader class="d-flex align-items-center">
            <h5 class="mb-0">{{ $t('Dashboard.UnshippedOrder') }}</h5>
            <i class="fas fa-info-circle text-info ml-2" v-c-tooltip="{content: $t('Dashboard.UnshippedOrderInfo'), placement: 'right'}" />
          </CCardHeader>
          <CCardBody class="p-0">
            <CDataTable
              id="OrderList"
              :items="UnShipmentList"
              :fields="OrderField"
              :loading="Loading"
              :noItemsView="noItemsView"
              addTableClasses="text-nowrap mb-0"
              responsive
            >
              <template #no-items-view>
                <CAlert color="success" class="mb-0">
                  <i class="fas fa-check-circle mr-1" />{{ $t('Dashboard.UnshippedOrderEmpty') }}
                </CAlert>
              </template>
              <template #OrderNum="{item}">
                <td class="text-left">
                  <a href="#" class="btn-link text-info" @click="HandleExternalLink(item.OrderNum)">
                    {{ item.OrderNum }}
                  </a>
                </td>
              </template>
              <template #CreateTime="{item}">
                <td>
                  {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
                </td>
              </template>
              <template #Amount="{item}">
                <td>
                  <span class="text-danger">{{ $store.state.currency + '$' }}{{ item.TotalAmount }}</span>
                </td>
              </template>
              <template #PaymentType="{item}">
                <td>
                  {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})
                </td>
              </template>
              <template #LogisticsType="{item}">
                <td>
                  {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})
                </td>
              </template>
              <template #PaymentStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.PaymentStatus]">
                    {{ $t('Order.PaymentStatusList.' + item.PaymentStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #LogisticsStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.LogisticsStatus]">
                    {{ $t('Order.LogisticsStatusList.' + item.LogisticsStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #OrderStatus="{item}">
                <td>
                  <CBadge :color="StatusClassOption[item.OrderStatus]">
                    {{ $t('Order.OrderStatusList.' + item.OrderStatus) }}
                  </CBadge>
                </td>
              </template>
              <template #BonusActionType="{item}">
                <td>
                  <CButton color="info" size="sm" class="mr-2" @click="HandleExternalLink(item.OrderNum)">
                    <CIcon name="cil-external-link" class="c-icon-custom-size mr-1" /> {{ $t('Order.ActivityLogEvent.CheckOrder') }}
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter class="p-0">
            <CButton block class="text-left py-2 px-3" @click="$router.push('/order/process/shipment')">
              <i class="fas fa-arrow-right mr-2" /> {{ $t('Dashboard.GoShipmentManage') }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </main>
</template>

<route>
{
  "meta": {
    "label": "概覽資訊"
  }
}
</route>

<script>
import { mapState } from 'vuex'
import { CChartBarSimple } from '@/components/charts'
import AdvanceWidgetDropdown from '@/components/widgets/AdvanceWidgetDropdown'
export default {
  name: 'Dashboard',
  components: {
    CChartBarSimple,
    AdvanceWidgetDropdown
  },
  layout: 'manage',
  data () {
    return {
      List: [],
      UnShipmentList: [],
      Loading: false,
      WeeklyDate: [],
      WeeklyReport: [],
      TodayOrder: {},
      YesterdayOrder: {},
      TodayRegister: 0,
      YesterdayRegister: 0,
      DailyStatistics: {},
      Submit: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      },
      StatusClassOption: {
        'P': 'warning',
        'A': 'primary',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    Field () {
      return [
        { key: 'OrderNum', label: this.$t('Order.OrderNum') },
        { key: 'OrderClient', label: this.$t('Order.Message.Client') },
        { key: 'EventTime', label: this.$t('Order.ActivityLogEvent.LeaveMessageTime') },
        { key: 'PaymentStatus', label: this.$t('Order.PaymentStatus') },
        { key: 'LogisticsStatus', label: this.$t('Order.LogisticsStatus') },
        { key: 'OrderStatus', label: this.$t('Order.OrderStatus') },
        { key: 'BonusActionType', label: this.$t('Organization/Member.BonusActionType') }
      ]
    },
    OrderField () {
      return [
        { key: 'OrderNum', label: this.$t('Order.OrderNum') },
        { key: 'Amount', label: this.$t('Order.Amount') },
        { key: 'PaymentType', label: this.$t('Order.PaymentTypeSystem') },
        { key: 'LogisticsType', label: this.$t('Order.LogisticsTypeSystem') },
        { key: 'PaymentStatus', label: this.$t('Order.PaymentStatus') },
        { key: 'LogisticsStatus', label: this.$t('Order.LogisticsStatus') },
        { key: 'OrderStatus', label: this.$t('Order.OrderStatus') },
        { key: 'CreateTime', label: this.$t('Order.CreateTime') }
      ]
    },
    ChartDateLabels () {
      const DateRange = []
      let CurrentDate = this.$dayjs(this.$dayjs().subtract(6, 'day'))
      while (CurrentDate <= this.$dayjs(this.$dayjs())) {
        DateRange.push(CurrentDate.format('YYYY-MM-DD'))
        CurrentDate = CurrentDate.add(1, 'day')
      }
      return DateRange
    },
    WeeklyOrderItems () {
      return this.ChartDateLabels.map(date => {
        const Data = this.WeeklyReport.find(item => item._id === date) || {}
        return Data.TotalItems || 0
      })
    },
    WeeklyOrderAmount () {
      return this.ChartDateLabels.map(date => {
        const Data = this.WeeklyReport.find(item => item._id === date) || {}
        return Data.TotalAmount || 0
      })
    },
    WeeklyRegister () {
      return this.ChartDateLabels.map(date => {
        const Data = this.DailyStatistics[date]
        return Data ? (Data.MemberRegister || 0) : this.TodayRegister
      })
    },
    WeeklyPaymentAmount () {
      return this.ChartDateLabels.map(date => {
        const Data = this.WeeklyReport.find(item => item._id === date) || {}
        return Data.PaymentAmount || 0
      })
    }
  },
  mounted() {
    this.Init()
  },
  methods: {
    Init () {
      this.Loading = true
      const Execute = []
      this.$Progress.start()
      setTimeout(() => {
        const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
        if ((PermissionSession.Features.Order || []).includes('Message')) {
          Execute.push(
            this.GetOrderMessageList()
          )
        }
        if ((PermissionSession.Navigation.Report || []).includes('Order') || (PermissionSession.Navigation.Report || []).includes('Profit') || (PermissionSession.Navigation.Organization || []).includes('Member')) {
          Execute.push(
            this.GetReporting()
          )
        }
        if ((PermissionSession.Navigation.Order || []).includes('ManageProcess')) {
          Execute.push(
            this.GetUnShipmentOrderList()
          )
        }
        return Promise.all(Execute).then(() => {
          this.Loading = false
          this.$Progress.finish()
        }).catch((err) => {
          this.Loading = false
          this.$Progress.fail()
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Global/ErrorMessage') + err.msg,
            type: (err.type ? err.type : 'error')
          })
        })
      }, 1500)
    },
    GetReporting() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/indexReport',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.TodayOrder = data.Data.TodayOrder
        this.YesterdayOrder = data.Data.YesterdayOrder
        this.TodayRegister = data.Data.TodayRegister
        this.YesterdayRegister = data.Data.YesterdayRegister
        this.WeeklyDate = data.Data.WeeklyDate
        this.WeeklyReport = data.Data.WeeklyReport
        this.DailyStatistics = data.Data.DailyStatistics
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    HandleExternalLink(slug) {
      return window.open(`/order/detail/${slug}`, '_blank')
    },
    GetOrderMessageList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/data',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            StartTime: this.$dayjs().tz('Asia/Taipei').subtract(3, 'month').unix(),
            EndTime: this.$dayjs().tz('Asia/Taipei').unix(),
            Taxonomy: 'ProductOrder',
            Order: 'otn',
            'Variable.MessageProcessed': false
          }
        }
      }).then(({data}) => {
        this.List = data.map((el) => {
          const CustomMessage = (el.Message || []).filter(item => item.Origin === 'Client')
          return {
            OrderNum: el.OrderNum,
            Content: CustomMessage.length > 0 ? CustomMessage[0].Content.replace(/<(?:.|\s)*?>/g, '') : '',
            EventTime: CustomMessage.length > 0 ? CustomMessage[0].CreateTime : '',
            OrderStatus: el.Status.Order,
            PaymentStatus: el.Status.Payment,
            LogisticsStatus: el.Status.Logistics
          }
        })
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    GetUnShipmentOrderList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/data',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            Taxonomy: 'ProductOrder',
            Order: 'nto',
            'Status.Payment': ['Y', 'A'],
            'Status.Logistics': ['P'],
            'Status.Order': ['Y']
          }
        }
      }).then(({ data }) => {
        this.UnShipmentList = data.map((item) => {
          item.OrderStatus = item.Status.Order
          item.PaymentStatus = item.Status.Payment
          item.LogisticsStatus = item.Status.Logistics
          return item
        })
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    UpdateMessageStatus (OrderNum) {
      this.$Progress.start()
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/edit',
        method: 'post',
        data: {
          orderNum: OrderNum,
          origin: 'Manage',
          updateData: {
            'Variable.MessageProcessed': true
          }
        }
      }).then(() => {
        this.$Progress.finish()
        this.GetOrderMessageList().then(() => {
          this.Loading = false
        })
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/MessageConfirmSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>
